import React from 'react';
import { Link } from "@StarberryUtils";
import _ from "lodash";
import { useLocation } from "@reach/router";
import ModalTiny from "react-bootstrap/Modal";
import RegisterForm from "../../forms/register-form";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { CloseIcon } from '../../Icons/Icons';
import "./PropertyGridCard.scss";

const OffPlanCard = (props) => {
  const location = useLocation();
  const { item } = props;

  const [modalAskformOpen, setAskformOpen] = React.useState(false);
  const openAskformModal = () => {
    setAskformOpen(true);
  };
  const closeAskformModal = () => {
    setAskformOpen(false);
  };

  //console.log("item", item);

  return (
    <>
      <div className="property-grid-card-wrap">
        <div className="properties-img-wrapper img-zoom">
          <Link to={`/off-plan-properties/${item?.URL}`}>
            <ImageTransform
              imagesources={item?.imageUrl}
              renderer="srcSet"
              imagename="off-plan-properties.Tile_Image.similar_properties"
              attr={{ alt: `${item?.Location} - Dacha`, class: '', loading: 'eager' }}
              imagetransformresult={item?.processedImages}
              id={item?.id}
            />
          </Link>
        </div>
        <div className="property-grid-card-info">
          <div className="properties-info">
            <h2 className="text-md-bold-20-16">
              <Link to={`/off-plan-properties/${item?.URL}`}>{item?.Title}</Link>
            </h2>
            <div className="price text-md-bold-20-16">
              <span className="title">Prices from:&nbsp;</span>
              <span className="total-price">
                AED&nbsp;{item?.Price?.toLocaleString()}
              </span>
            </div>
            <div className="about-info-wrap">
              <div className="about-info">
                <span className="text-xs-bold">Location:&nbsp;</span>
                <span className="text-xs">{item?.Location}</span>
              </div>
              <div className="about-info">
                <span className="text-xs-bold">Developer:&nbsp;</span>
                <span className="text-xs">{item?.Developer}</span>
              </div>
              <div className="about-info">
                <span className="text-xs-bold">Development Type:&nbsp;</span>
                <span className="text-xs">{item?.Developer_Type}</span>
              </div>
            </div>
          </div>

          <div className="offplan-contact-info">
            <a href="javascript:void(0)" onClick={openAskformModal} className="btn btn-light-blue">
              <span>Register</span>
            </a>
            <Link to={`/off-plan-properties/${item?.URL}`} className="btn btn-light-blue">
              <span>View Property</span>
            </Link>
          </div>
        </div>
      </div>     
      <ModalTiny show={modalAskformOpen} onHide={closeAskformModal} className="modal-form-wrapper">
      <ModalTiny.Header >
      <div class="modal-close-btn" onClick={closeAskformModal}><CloseIcon /></div>
        <ModalTiny.Title><h4>Register your interest</h4></ModalTiny.Title>
      </ModalTiny.Header>
      <ModalTiny.Body >
          <RegisterForm />
      </ModalTiny.Body>
    </ModalTiny>
    </>
  );
};

export default OffPlanCard;